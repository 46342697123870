import { Font } from '@react-pdf/renderer';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { PageLoader, ToastNotificationArea } from './components';
import { ExternalRoute, ProtectedRoute } from './components/protected-route';
import { AuthContext } from './context';
import {
    AllClientPage,
    DashboardPage,
    ForgetPasswordPage,
    LoginPage,
    ManageUsersPage,
    NoMatchPage,
    RegistrationRequestPage,
    Reporting,
    ResetPasswordPage,
    ServicePage,
    TransactionServicesPage,
    UserActivitiesPage,
    UserProfilePage,
    Vcard,
    ViewClientPage,
    ViewServicePage,
    ViewTransactionServicePage,
} from './pages';
import { BookingsPage, ViewBookingPage } from './pages/admin/bookings';
import { CreateBookingPage } from './pages/admin/bookings/create-booking';
import { CreateBookingForm, CreateBookingInvoice } from './pages/admin/bookings/create-booking/components';
import { ClientServicePage } from './pages/admin/client-services/service-page';
import { VerifyEmailPage } from './pages/auth/registration';
import { CustomerSurveyPage } from './pages/customer-survey';
import { Invoice } from './pages/invoice';
import LandingPage from './pages/landing-page/landing-page';
import { ProtocolOfficersPage } from './pages/protocol-officer/protocol-officer-page';
import { NewRoles, Roles } from './util/constants';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

export default () => (
    <AuthContext.Provider>
        <PageLoader />
        <Switch>
            <ExternalRoute exact path="/" component={LandingPage} />
            <ExternalRoute exact path="/login" component={LoginPage} />
            <ExternalRoute exact path="/forget-password" component={ForgetPasswordPage} />
            <ExternalRoute path="/reset-password/:code" component={ResetPasswordPage} />
            <ExternalRoute path="/customer-survey/:code" component={CustomerSurveyPage} />
            <ExternalRoute path="/verify-email/:code" component={VerifyEmailPage} />

            {/* TODO: Protected routes here */}
            <ProtectedRoute path="/app/profile" component={UserProfilePage} />
            <ProtectedRoute path="/app/dashboard" component={DashboardPage} />
            <ProtectedRoute path="/app/users" component={ManageUsersPage} role={Roles.Admin} />
            <ProtectedRoute
                path="/app/protocols"
                component={ProtocolOfficersPage}
                role={[Roles.Admin, Roles.AirportProtocolCoordinator]}
            />
            <ProtectedRoute
                path="/app/activities"
                component={UserActivitiesPage}
                role={[Roles.Admin, Roles.AirportManager, Roles.AirportProtocolCoordinator, Roles.Client]}
            />
            <ProtectedRoute
                path="/app/clients/registration-request"
                component={RegistrationRequestPage}
                role={Roles.Admin}
            />
            <ProtectedRoute path="/app/services" component={ServicePage} role={[Roles.Client, Roles.Admin]} />
            <ProtectedRoute
                path="/app/service/:service_id"
                component={ViewServicePage}
                role={[Roles.Client, Roles.Admin]}
            />
            <ProtectedRoute
                path="/app/clients"
                component={AllClientPage}
                role={[Roles.Admin, Roles.AirportManager, ...NewRoles]}
            />
            <ProtectedRoute
                path="/app/client/approval/:client_id"
                component={RegistrationRequestPage}
                role={[Roles.Admin, Roles.AirportManager]}
            />
            <ProtectedRoute
                path="/app/client/:client_id"
                component={ViewClientPage}
                role={[Roles.Admin, Roles.AirportManager]}
            />
            <ProtectedRoute
                path="/app/bookings"
                component={BookingsPage}
                role={[Roles.Client, Roles.Admin, Roles.AirportProtocolCoordinator, Roles.AirportManager]}
            />
            <ProtectedRoute
                path="/app/booking/:booking_id"
                component={ViewBookingPage}
                role={[Roles.Client, Roles.Admin, Roles.AirportProtocolCoordinator, Roles.AirportManager]}
            />
            <ProtectedRoute
                path="/app/client-services/:client_id"
                component={ClientServicePage} /* role={[Roles.Client, Roles.Admin]} */
            />
            <ProtectedRoute
                path="/app/client-service/:service_id/create-booking/:client_id"
                component={CreateBookingPage}
            />
            <ProtectedRoute
                path="/app/client-service/:service_id/create-porthacourt-booking/:client_id"
                component={CreateBookingPage}
            />

            <ProtectedRoute
                path="/app/transaction-services"
                component={TransactionServicesPage}
                role={[
                    Roles.Accountant,
                    Roles.Auditor,
                    Roles.Commercial,
                    Roles.Admin,
                    Roles.AirportProtocolCoordinator,
                ]}
            />

            <ProtectedRoute
                path="/app/reporting"
                component={Reporting}
                role={[Roles.AirportManager, Roles.Admin, Roles.AirportProtocolCoordinator]}
            />

            {/* <ProtectedRoute
                path="/app/vcard"
                component={Vcard}
                role={[Roles.AirportManager, Roles.Admin, Roles.AirportProtocolCoordinator]}
            /> */}
            <ProtectedRoute
                path="/app/transaction-service/:transactionId"
                component={ViewTransactionServicePage}
                role={[
                    Roles.Accountant,
                    Roles.Auditor,
                    Roles.Commercial,
                    Roles.Admin,
                    Roles.AirportProtocolCoordinator,
                ]}
            />
            <Route path="/invoice" component={Invoice} />
            {/* When there is no page match */}
            <Route path="*">
                <NoMatchPage />
            </Route>
        </Switch>
        <ToastNotificationArea />
    </AuthContext.Provider>
);
